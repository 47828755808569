<template>
  <PageListLayout
    icon="mdi-surround-sound"
    title="Yêu cầu báo giá"
    subTitle="Danh sách yêu cầu báo giá"
    useAdd
    titleAdd="Thêm Yêu Cầu Báo Giá"
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.customer_id"
          :items="customersList"
          label="Khách hàng"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="style_auto_complate"
                  :title="data.item.name"
                  v-html="data.item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.request_date`]="{ item }">
        {{ dateFormat(item.request_date) }}
      </template>
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:[`item.request_type`]="{ item }">
        <span>{{ getSourceName(requestTypeList, item.request_type) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span :class="`badge ${item.status && StyleStatus[item.status]}`">{{
          handleStatus(item.status)
        }}</span>
        <v-tooltip bottom v-if="item.out_off_date_soon">
          <template v-slot:activator="{ on }">
            <v-icon class="pointer ml-1" v-on="on" color="#f00"
              >mdi-alert-decagram</v-icon
            >
          </template>
          <span>Báo giá sắp hết hạn</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.order`]="{ item }">
        <span>{{
          item.parent ? item.parent.order + "-" + item.order : item.order
        }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="btnLoading == item.id"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="goEdit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="print(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-printer </v-icon>
                In yêu cầu báo giá</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="makeCopyItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-content-copy </v-icon>
                Tạo bản sao</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              @click="cancelProductPriceRequest(item.id)"
              v-if="item.status != 3"
            >
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-cancel </v-icon>
                Huỷ yêu cầu báo giá</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="deleteItem(item)" v-if="item.status != 3">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa yêu cầu báo giá</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
  </PageListLayout>
</template>
<script>
import {
  getProductPriceRequest,
  deletedProductPriceRequest,
  cancelProductPriceRequest,
  printProductPriceRequest,
} from "@/api/yeucaubaogia";
import { debounce } from "lodash";
import logo from "../../../assets/images/logoGreenStar.png";
import { Status, StyleStatus } from "@/constants/yeucaubaogia";
import KHACHHANG from "@/api/khachhang";
import { formatDate } from "@/utils/date";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      productTypesData: [],
      btnLoading: -1,
      menu: {},
      loading: false,
      search: "",
      formSearch: {},
      headers: [
        {
          text: "Mã yêu cầu báo giá",
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: "Ngày yêu cầu báo giá", value: "request_date" },
        { text: "Loại yêu cầu báo giá", value: "request_type" },
        { text: "Đơn vị phát sinh", value: "customers.name" },
        // { text: "Địa chỉ", value: "customer_address" },
        { text: "Ghi chú", value: "description" },
        // { text: "Người yêu cầu", value: "user.name" },
        { text: "Trạng thái", value: "status" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
      requestTypeList: [
        { value: 1, name: "Yêu cầu báo giá mới" },
        { value: 2, name: "Yêu cầu báo giá thay thế" },
        { value: 3, name: "Yêu cầu báo giá bổ sung" },
      ],
      StyleStatus,
      Status,
      customersList: [],
    };
  },
  computed: {},
  created() {
    this.getAllList();
    this.getCustomersList();
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function(val) {
        this.page = 1;
        this.getAllList();
      }, 300),
    },
  },
  methods: {
    dateFormat(e) {
      return formatDate(e);
    },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    handleStatus(status) {
      if (!status) {
        return "";
      }
      let find = Status.find((x) => x.id == status);
      return find.name;
    },
    async getCustomersList() {
      let res = await KHACHHANG.getAllCustomers();
      this.customersList = res.data;
    },
    async getAllList() {
      this.loading = true;
      let data = await getProductPriceRequest({
        page: this.page,
        perPage: this.itemsPerPage,
        ...this.formSearch,
      });
      this.loading = false;
      this.tableData = data.data;
      // this.tableData[0].out_off_date_soon = true;
      this.pageCount = data.last_page;
    },
    changePage(val) {
      this.page = val;
      // console.log(this.page);
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa sản phẩm",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa sản phẩm " +
          "<strong>" +
          item.code +
          " ?" +
          "</strong>",
        action: () => deletedProductPriceRequest({ id: item.id }),
        onDone: this.getAllList,
      });
    },
    async cancelProductPriceRequest(id) {
      try {
        await cancelProductPriceRequest(id);
      } finally {
        this.getAllList();
      }
    },
    getSourceName(list, id) {
      return list.find((item) => item.value === id)
        ? list.find((item) => item.value === id).name
        : null;
    },
    createItem() {
      this.$router.push({ path: "/kinhdoanh/themyeucaubaogia" });
    },
    goEdit(item) {
      this.$router.push({ path: `/kinhdoanh/capnhatyeucaubaogia/${item.id}` });
    },
    makeCopyItem(item) {
      this.$router.push({
        path: `/kinhdoanh/themyeucaubaogia?bansao=${item.id}`,
      });
    },
    async print(item) {
      try {
        this.btnLoading = item.id;
        const res = await printProductPriceRequest(item.id);
        if (res.isError) {
          this.$store.dispatch("toastAlert/error", "Lỗi in yêu cầu báo giá");
          return;
        }
        saveAs(new Blob([res]), "Chi_tiet_yeu_cau_bao_gia.docx");
      } catch (error) {
        this.$store.dispatch("toastAlert/error", "Lỗi in yêu cầu báo giá");
      } finally {
        this.btnLoading = -1;
      }
    },
  },
};
</script>
<style scoped>
.style_auto_complate {
  max-width: 200px;
}
</style>
