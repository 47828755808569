import request from "../utils/request";

export function getProductPriceRequest(params) {
  return request({
    url: "productpricerequest",
    method: "get",
    params,
  });
}

export function getProductPriceRequestById(params) {
  return request({
    url: "productpricerequestbyid",
    method: "get",
    params,
  });
}

export function getAllProductPriceRequest() {
  return request({
    url: "allproductpricerequest",
    method: "get",
  });
}
export function getProductPriceRequestCount() {
  return request({
    url: "productpricerequest-count",
    method: "get",
  });
}

export function addProductPriceRequest(data) {
  return request({
    url: "productpricerequest",
    method: "post",
    data,
  });
}

export function updateProductPriceRequest(id, data) {
  return request({
    url: `productpricerequest/${id}`,
    method: "post",
    data,
  });
}

export function editProductPriceRequest(data) {
  return request({
    url: "productpricerequest",
    method: "put",
    data,
  });
}

export function deletedProductPriceRequest(data) {
  return request({
    url: "/productpricerequest",
    method: "delete",
    data,
  });
}

export function uploadProductImage(data) {
  return request({
    url: "/uploadproductimage",
    method: "post",
    data,
  });
}

export function cancelProductPriceRequest(id) {
  return request({
    url: `/cancelProductPriceRequest/${id}`,
    method: "put",
  });
}

export function updateDetailProduct(data, id) {
  return request({
    url: `productpricerequestdetail/${id}`,
    method: "put",
    data,
  });
}
export function printProductPriceRequest(id) {
  return request({
    url: `/productpricerequest-print/${id}`,
    responseType: "blob",
  });
}
