var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListLayout',{attrs:{"icon":"mdi-surround-sound","title":"Yêu cầu báo giá","subTitle":"Danh sách yêu cầu báo giá","useAdd":"","titleAdd":"Thêm Yêu Cầu Báo Giá"},on:{"add":_vm.createItem,"reset-filter":_vm.resetFilter}},[_c('template',{slot:"filter"},[_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","clearable":"","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formSearch.search),callback:function ($$v) {_vm.$set(_vm.formSearch, "search", $$v)},expression:"formSearch.search"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"items":_vm.customersList,"label":"Khách hàng","outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"style_auto_complate",attrs:{"title":data.item.name},domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}]),model:{value:(_vm.formSearch.customer_id),callback:function ($$v) {_vm.$set(_vm.formSearch, "customer_id", $$v)},expression:"formSearch.customer_id"}})],1)]),_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ...","pageCount":_vm.pageCount,"classPaging":"pt-2"},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.request_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.request_date))+" ")]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.request_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSourceName(_vm.requestTypeList, item.request_type)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("badge " + (item.status && _vm.StyleStatus[item.status]))},[_vm._v(_vm._s(_vm.handleStatus(item.status)))]),(item.out_off_date_soon)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pointer ml-1",attrs:{"color":"#f00"}},on),[_vm._v("mdi-alert-decagram")])]}}],null,true)},[_c('span',[_vm._v("Báo giá sắp hết hạn")])]):_vm._e()]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.parent ? item.parent.order + "-" + item.order : item.order))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.btnLoading == item.id,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goEdit(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Cập nhật")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.print(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-printer ")]),_vm._v(" In yêu cầu báo giá")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.makeCopyItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-content-copy ")]),_vm._v(" Tạo bản sao")],1)],1),(item.status != 3)?_c('v-list-item',{on:{"click":function($event){return _vm.cancelProductPriceRequest(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-cancel ")]),_vm._v(" Huỷ yêu cầu báo giá")],1)],1):_vm._e(),(item.status != 3)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa yêu cầu báo giá")],1)],1):_vm._e()],1)],1)]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }